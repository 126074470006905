module.exports = {
  siteTitle: 'Ayesha Siddiqua',
  siteDescription: 'Ayesha Siddiqua, Ph.D in Mass Communication is currently serving as an Associate Professor at the Department of Media and Communication Studies, National University of Modern languages, Islamabad. Her research interests range from the intricacies of media framing practices to the complexities of conflict-sensitive journalism, ethnocentrism, peace and war journalism, and the pressing concern of cyber hate speech. She has effectively engaged both quantitative and qualitative research techniques for comprehending socially relevant media-related inquiries. ',
  siteKeywords: 'Ayesha Siddiqua, Ph.D, Mass Communication, Associate Professor, Media and Communication Studies, National University of Modern Languages, Islamabad, Media framing practices, Conflict-sensitive journalism, Ethnocentrism, Peace journalism, War journalism, Cyber hate speech, Quantitative research, Qualitative research, Socially relevant media inquiries',
  siteUrl: 'https://ayeshasiddiqua.me/',
  siteLanguage: 'en_US',
  googleAnalyticsID: 'oi4365oi3uo36u43',
  googleVerification: 'l3kj6lkj3543j65',
  name: 'Ayesha Siddiqua',
  location: 'Islamabad, Pakistan',
  email: 'asiddiqua@numl.edu.pk',
  github: '',
  twitterHandle: '@',
  socialMedia: [
    {
      name: 'Linkedin',
      url: 'https://www.linkedin.com/in/dr-ayesha-siddiqua-267233b0/',
    },
    {
      name: 'Twitter',
      url: 'https://twitter.com/Asiddiqua05/',
    },
    {
      name: 'Facebook',
      url: 'https://www.facebook.com/ayesha.siddiqua.37201?mibextid=ZbWKwL'
    },
    {
      name: 'Location',
      url: 'https://maps.app.goo.gl/QdcqwcFXP2hrrdj38'
    }
  ],

  navLinks: [
    {
      name: 'About',
      url: '/#about',
    },
    {
      name: 'Publications',
      url: '/#projects',
    }
  ],

  navHeight: 100,

  colors: {
    green: '#64ffda',
    navy: '#0a192f',
    darkNavy: '#020c1b',
  },

  srConfig: (delay = 200) => ({
    origin: 'bottom',
    distance: '20px',
    duration: 500,
    delay,
    rotate: { x: 0, y: 0, z: 0 },
    opacity: 0,
    scale: 1,
    easing: 'cubic-bezier(0.645, 0.045, 0.355, 1)',
    mobile: true,
    reset: false,
    useDelay: 'always',
    viewFactor: 0.25,
    viewOffset: { top: 0, right: 0, bottom: 0, left: 0 },
  }),
};
