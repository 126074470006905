import React from 'react';

const IconFacebook = () => (
  <svg xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 24 24">
    <title>Facebook</title>
    <path d="M21.18 2H2.82A2.82 2.82 0 0 0 0 4.82V21.18A2.82 2.82 0 0 0 2.82 24H12V14.75H9.06V11.46H12V9.14c0-3.28 2.01-5.06 4.93-5.06 1.41 0 2.62.11 2.98.16V7.8H17.8c-1.6 0-1.91.76-1.91 1.88v2.47h3.83L18.8 14.75h-3.79v9.25a2.82 2.82 0 0 0 2.82 2.82h15.36A2.82 2.82 0 0 0 24 21.18V4.82A2.82 2.82 0 0 0 21.18 2z" />
  </svg>
);

export default IconFacebook;
